import React, { useState } from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/Seo'

import DynamicZone from '../components/DynamicZone/DynamicZone'
import ContactForm from '../components/ContactForm/ContactForm'

export default function BeautyPage({ data: { strapi: { beautyPage } }, location }) {
	const [ subject, setSubject ] = useState('')

	return (
		<>
			<SEO title="Beauty" description={ beautyPage.seoDescription } pathname={ location.pathname } cssClass="t-beauty" />

			{ beautyPage?.content &&
				<DynamicZone components={ beautyPage.content } />
			}

			<div className="wrapper">
				<div className="form-page">
					<div className="form-page__header">
						<h1 className="form-page__heading">Contact Us</h1>
						<p className="form-page__info">
							Please fill out the following form if you have any queries or questions.
						</p>
					</div>
					<ContactForm subject={ subject } setSubject={ setSubject } enquiryPlaceholder="Enquiry" isBeautyPage={ true } />
				</div>
			</div>
		</>
	)
}

export const query = graphql`
	query beautyPageQuery {
		strapi {
			beautyPage {
				seoDescription
				content {
					__typename
					... on StrapiQuery_ComponentBeautyTreatments {
						id
						name
						description
						illustration
						effects {
							effect
							id
						}
						whyChooseTitle
						whyChoose {
							whyChooseItem
							id
						}
						pricing {
							price
							id
						}
					}
					... on StrapiQuery_ComponentBeautyHeader {
						beautyDescription
						title
					}
				}
			}
		}
	}
`